import React, { useEffect, useState, useRef } from 'react';
import styles from './CreatePost.module.css';

import { Toolbar, IconButton, makeStyles, Paper, Grid } from '@material-ui/core';
import TextArea from '../TextArea';

import PostIcon from '../svgs/PostIcon';
import XIcon from '../svgs/XIcon';
import GalleryIcon from '../svgs/GalleryIcon';

import { rwbApi } from '../../../../shared/apis/api';
import {
  isNullOrEmpty,
  getHyperlinks,
  validURL,
  validTaggedUsers,
} from '../../../../shared/utils/Helpers';
import Loading from '../Loading';
import UsersList from './UsersList';
import { userProfile } from '../../../../shared/models/UserProfile';
import imageHandler from '../ImageHandler.react';
import {
  logFeedCreatePost,
  EXECUTION_STATUS,
  logTagUser,
  webSectionName,
  logEditPost,
  logTagEveryone,
  logIncludeOpenGraph,
} from '../../../../shared/models/Analytics';
import ShareChallengeBox from './ShareChallengeBox';
import SitePreviewCard from '../cards/SitePreviewCard';
import { FEED_TAGS } from '../../../../shared/constants/Labels';
import { MAX_IMAGE_HEIGHTS, WEB_MIMES } from '../../../../shared/constants/ImageConstants';
import ImageWithAspectRatio from './ImageWithAspectRatio.react';
import ChevronRightIcon from '../svgs/ChevronRightIcon';
import { DOMAIN } from '../../../../shared/constants/URLs';
import { MAX_POST_LENGTH } from '../../../../shared/constants/Restrictions';
import PostImageAdder from './PostImageAdder.react';
import { CreationPostImageDisplay } from './PostImageDisplay.react';

const NON_USER_TAGS = [FEED_TAGS.EVERYONE];
const USER_SUGGESTION_API_INTERVAL = 1000;
let apiTimeout = null;

const CreatePost = ({
  eventID = null,
  groupID = null,
  challengeID = null,
  type,
  mergeNewPost,
  closeModalHandler,
  text,
  image,
  images,
  tagged,
  id,
  eventName,
  chapterName,
  eventStartTime,
  miles,
  steps,
  hours,
  minutes,
  seconds,
  groupRecordType,
  activitySubType,
  eventRecordType,
  group_record_type,
  activity_sub_type,
  event_record_type,
  challengeId,
  graphData,
}) => {
  const [postText, setPostText] = useState(text || '');
  const [postImages, setPostImages] = useState(images || []);
  const [postTagged, setPostTagged] = useState(tagged || []);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [searchingUsers, setSearchingUsers] = useState(false);
  const [searchingLocation, setSearchingLocation] = useState(null);
  const [userInput, setUserInput] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState('');
  const [userResults, setUserResults] = useState([]);
  const [tallImg, setTallImg] = useState(false);
  const [postID, setPostID] = useState(id || null);
  const [links, setLinks] = useState(text ? getHyperlinks(text) : []);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEventHost, setIsEventHost] = useState(false);
  const [previewData, storePreviewData] = useState(graphData || null);
  const [canceledLinkView, setCanceledLinkView] = useState(null);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'var(--magenta)',
      height: 64,
    },
  }));
  const classes = useStyles();

  const onImgLoad = ({ target: img }) => {
    setTallImg(img.offsetWidth / img.offsetHeight < 0.75);
  };

  useEffect(() => {
    if (groupID && eventID) {
      Promise.all([
        rwbApi.isGroupAdmin(groupID),
        rwbApi.getMobileEvent(eventID),
      ]).then(([adminData, eventData]) => {
        setIsAdmin(adminData.is_admin);
        setIsEventHost(eventData.host.id == userProfile.getUserProfile().id);
      });
    }
  }, []);

  const textChangeHandler = (value) => {
    // TODO: Handle backspacing early in the post while searching for users
    // TODO: Highlight color of valid user, could not find colors specific parts of text input while making it editable.
    // Once this is determined, validTaggedUsers could be modified to display valid users with the proper color
    setPostText(value);

    // elastic search with value after @
    if (value.charAt(value.length - 1) === '@' && !searchingUsers) {
      setSearchingUsers(true);
      setSearchingLocation(value.length);
    }

    if (searchingUsers) {
      // if the user deletes the "@" symbol, stop searching
      if (value.charAt(searchingLocation - 1) !== '@') {
        setSearchingUsers(false);
        setSearchingLocation(null);
      } else searchUser(value.slice(searchingLocation));
    }
    const links = getHyperlinks(value);
    if (links?.length) {
      setLinks(links);
      if (
        canceledLinkView &&
        links[links.length - 1] !== canceledLinkView &&
        validURL(links[links.length - 1])
      ) {
        setCanceledLinkView(null);
      }
    } else {
      if (canceledLinkView) {
        setCanceledLinkView(null);
      }
      setLinks([]);
    }
  };

  const searchUser = (text) => {
    // this is to prevent too frequent API call
    if (apiTimeout) clearTimeout(apiTimeout);
    apiTimeout = setTimeout(() => {
      const userList = [];
      setIsLoadingUsers(true);
      if (isAdmin || isEventHost) {
        NON_USER_TAGS.forEach((tag) => {
          if (tag.indexOf(text) === 0) {
            userList.push({
              _type: tag,
              name: tag,
            });
          }
        });
      }
      rwbApi.searchUser(text).then((result) => {
        setUserInput(text);
        setUserResults(userList.concat(result));
        setIsLoadingUsers(false);
      });
    }, USER_SUGGESTION_API_INTERVAL);
  };

  const handleSelectedUser = (user) => {
    let taggedUsers = postTagged;
    let fullPostText = postText;
    taggedUsers.push(user);
    const lastIndex = fullPostText.lastIndexOf(`@${userInput}`);
    fullPostText = fullPostText.slice(0, lastIndex) + `@${user.name}`;
    // the font looks like a space is added, which could cause users to send invalid data
    // to prevent this, add an space after selecitng a tagged user
    fullPostText += ' ';
    setSearchingUsers(false);
    setUserResults([]);
    setPostTagged(taggedUsers);
    setPostText(fullPostText);
  };

  const createPostHandler = () => {
    const tagged = validTaggedUsers(postTagged, postText);
    // image files objects have a name field, so only try and go through the upload flow if it is present
    if (postImages.length) {
      setIsLoading(true);
      imageHandler(postImages, 'post')
        .then((result) => {
          const data = {
            media: result,
            text: postText,
            tagged: tagged,
            tag_everyone: !!(
              postText && postText.indexOf(`@${FEED_TAGS.EVERYONE}`) > -1
            ),
          };
          if (postID) updateFeed(data);
          else putFeed(data);
        })
        .catch((error) => {
          // Unable to retrieve the upload URL
          setIsLoading(false);
          alert('Error uploading post image to Team RWB Servers.');
        });
    } else if (!isNullOrEmpty(postText)) {
      setIsLoading(true);
      const data = {
        media: postImages,
        text: postText,
        tagged: tagged,
        tag_everyone: !!(
          postText && postText.indexOf(`@${FEED_TAGS.EVERYONE}`) > -1
        ),
      };
      if (
        challengeID &&
        eventID &&
        (miles || steps || hours || minutes || seconds)
      ) {
        const duration = (
          parseInt(hours || 0) * 60 +
          parseInt(minutes || 0) +
          parseInt(seconds || 0) / 60
        ).toString();
        const workout = {
          event_id: parseInt(eventID),
          event_name: eventName,
          chapter_name: chapterName,
          event_start_time: eventStartTime,
          miles: miles,
          steps: steps,
          minutes: duration,
        };
        data.workout = workout;
      }
      if (postID) {
        updateFeed(data);
      } else putFeed(data);
    } else {
      alert('Unable to upload an empty post!');
      setIsLoading(false);
      return;
    }
  };

  const handlePostMade = (user) => {
    // retrieving the feed after so the post is in existence and can be reacted to
    // (small delay to ensure stream gets the post)
    setTimeout(
      () =>
        rwbApi.getUserFeed(user.id).then(() => {
          setIsLoading(false);
          mergeNewPost();
          closeModalHandler();
        }),
      100,
    );
  };

  const baseAnalyticsObj = () => {
    let obj = {
      // click_text: '', // there is no text when creating a post, you click on the submit arrow
      has_image: postImages?.length > 0 ? true : false,
      section_name: webSectionName(),
    };
    // group record type, event_record_type,
    if (groupRecordType) obj.group_record_type = groupRecordType;
    else if (group_record_type) obj.group_record_type = group_record_type;
    if (activitySubType) obj.activity_sub_type = activitySubType;
    else if (activity_sub_type) obj.activity_sub_type = activity_sub_type;
    if (eventRecordType) obj.event_record_type = eventRecordType;
    else if (event_record_type) obj.event_record_type = event_record_type;
    if (eventID) obj.event_id = `${eventID}`;
    if (challengeID) obj.challenge_id = `${challengeID}`;
    else if (challengeId) obj.challenge_id = `${challengeId}`;
    if (groupID) obj.group_id = `${groupID}`;
    return obj;
  };

  const checkIncludeOpenGraph = () => {
    return (
      previewData &&
      links?.length &&
      (!canceledLinkView || canceledLinkView !== links[links.length - 1])
    );
  };

  const postLogger = (data, analyticsObj) => {
    const includeOpenGraph = checkIncludeOpenGraph();
    if (data.tagged.length > 0) logTagUser(analyticsObj);
    if (data.tag_everyone) logTagEveryone(analyticsObj);
    if (includeOpenGraph) logIncludeOpenGraph(analyticsObj);
    if (postID) {
      analyticsObj.object_post = postID;
      logEditPost(analyticsObj);
    } else {
      logFeedCreatePost(analyticsObj);
    }
  };

  const putFeed = (data) => {
    const user = userProfile.getUserProfile();
    const includeOpenGraph = checkIncludeOpenGraph();
    let analyticsObj = baseAnalyticsObj();
    // assume success, change on failure
    analyticsObj.execution_status = EXECUTION_STATUS.success;
    if (includeOpenGraph) {
      data.open_graph = {
        description: previewData.description,
        image_url: previewData.image,
        title: previewData.title,
        url: previewData.url,
      };
    }
    // TODO test, but this will allow usage of just putFeed
    let timelineType;
    let timelineId;
    if (type === 'event') {
      timelineType = 'mobile_event';
      timelineId = eventID;
    }
    else if (type === 'group'){
      timelineType = 'group';
    }
    else if (type === 'challenge'){
      timelineType = 'challenge';
      timelineId = challengeID;
    }
    if (type === 'user') {
      return rwbApi
        .putFeed(JSON.stringify(data))
        .then(() => {
          handlePostMade(user);
        })
        .catch((err) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          setIsLoading(false);
          alert('Unable to upload post to RWB Servers.');
        })
        .finally(() => {
          postLogger(data, analyticsObj);
        });
    } else if (type === 'event') {
      return rwbApi
        .createEventPost(JSON.stringify(data), eventID)
        .then(() => {
          handlePostMade(user);
        })
        .catch((error) => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          setIsLoading(false);
          alert('Unable to upload post to RWB Servers.');
        })
        .finally(() => {
          postLogger(data, analyticsObj);
        });
    } else if (type === 'group') {
      return rwbApi
        .createGroupPost(JSON.stringify(data), groupID)
        .then(() => {
          handlePostMade(user);
        })
        .catch(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          setIsLoading(false);
          alert('Unable to upload post to RWB Servers.');
        })
        .finally(() => {
          postLogger(data, analyticsObj);
        });
    } else if (type === 'challenge') {
      return rwbApi
        .createChallengePost(JSON.stringify(data), challengeID)
        .then(() => {
          handlePostMade(user);
        })
        .catch(() => {
          analyticsObj.execution_status = EXECUTION_STATUS.failure;
          setIsLoading(false);
          alert('Unable to upload post to RWB Servers.');
        })
        .finally(() => {
          postLogger(data, analyticsObj);
        });
    }
  };

  const updateFeed = (data) => {
    // edit post
    const includeOpenGraph = checkIncludeOpenGraph();
    if (includeOpenGraph) {
      data.open_graph = {
        description: previewData.description,
        image_url: previewData?.image || previewData?.image_url,
        title: previewData.title,
        url: previewData.url,
      };
    }
    let analyticsObj = baseAnalyticsObj();
    const user = userProfile.getUserProfile();
    return rwbApi
      .updatePost(JSON.stringify(data), postID)
      .then((result) => {
        handlePostMade(user);
        analyticsObj.execution_status = EXECUTION_STATUS.success;
      })
      .catch((error) => {
        setIsLoading(false);
        alert('Unable to update post to RWB Servers.');
        analyticsObj.execution_status = EXECUTION_STATUS.failure;
      })
      .finally(() => {
        postLogger(data, analyticsObj);
      });
  };

  return (
    <div className={styles.container}>
      <Loading size={100} color={'var(--white)'} loading={isLoading} />
      <Paper className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            onClick={closeModalHandler}
            className={classes.menuButton}
            color="inherit">
            <XIcon tintColor="var(--white)" />
          </IconButton>
          <p className="title">Post</p>
          <IconButton
            onClick={createPostHandler}
            className={classes.menuButton}
            color="inherit">
            <PostIcon />
          </IconButton>
        </Toolbar>
      </Paper>
      <div className={styles.content}>
        {eventName && (
          <div className={styles.shareChallengeBoxWrapper}>
            <ShareChallengeBox
              eventName={eventName}
              chapterName={chapterName}
              eventStartTime={eventStartTime}
              miles={miles}
              steps={steps}
              hours={hours}
              minutes={minutes}
              seconds={seconds}
            />
          </div>
        )}
        <TextArea
          placeholder="Write a post"
          value={postText}
          onChange={textChangeHandler}
          maxCharacters={MAX_POST_LENGTH}
          height={100}
        />
        {searchingUsers ? (
          <div className={styles.usersListContainer}>
            <UsersList
              usersSuggestions={userResults}
              loadingUsers={isLoadingUsers}
              onSelect={(user) => handleSelectedUser(user)}
            />
          </div>
        ) : null}
        {!canceledLinkView &&
          links.length &&
          validURL(links[links.length - 1]) ? (
          <Grid container spacing={2} className={styles.linkPreviewContainer}>
            <Grid item xs={6} key={links[links.length - 1]}>
              <SitePreviewCard
                key={links[links.length - 1]}
                link={links[links.length - 1]}
                cancelable={true}
                onClose={() => {
                  setCanceledLinkView(links[links.length - 1]);
                }}
                storePreviewData={storePreviewData}
                graphData={previewData}
              />
            </Grid>
          </Grid>
        ) : null}

      </div>
      <CreationPostImageDisplay postImages={postImages} updatePostImages={(images) => setPostImages(images)}/>
      {!eventName && (
        <PostImageAdder postImages={postImages} updatePostImages={(images) => setPostImages(images)} activityType={'post'}/>
      )}
    </div>
  );
};

export default CreatePost;
