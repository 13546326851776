import React from 'react';
import PropTypes from 'prop-types';

function ReplyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={10}
      fill="none"
      {...props}>
      <path
        fill="#041E42"
        d="M7.333 7.333V10L12 5.333 7.333.666V3.4C4 3.4 1.666 2.333 0 0c.667 3.333 2.667 6.667 7.333 7.333Z"
      />
    </svg>
  );
}

ReplyIcon.defaultProps = {
  width: 12,
  height: 10,
};

ReplyIcon.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tintColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ReplyIcon;
