import React, {useState, useRef, useEffect} from 'react';
import creationStyles from './CreatePost.module.css';
import createdStyles from './FeedListItem.module.css';
import XIcon from '../svgs/XIcon';
import {
  MAX_IMAGE_HEIGHTS,
  WEB_MIMES,
} from '../../../../shared/constants/ImageConstants';
import ImageWithAspectRatio from './ImageWithAspectRatio.react';
import ChevronRightIcon from '../svgs/ChevronRightIcon';
import {
  MAX_POST_IMAGES,
  MAX_POST_LENGTH,
} from '../../../../shared/constants/Restrictions';
import Carousel from './Carousel';
import PhotoView from './PhotoView';

const CreationPostImageDisplay = ({postImages, updatePostImages}) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const carouselScrollRef = useRef(null);

  const carouselScroll = (direction) => {
    const leftAmount = -300;
    const rightAmount = 300;

    if (carouselScrollRef.current) {
      carouselScrollRef.current.scrollBy({
        left: direction === 'L' ? leftAmount : rightAmount,
        behavior: 'smooth',
      });
      setTimeout(updateScrollArrows, 150); // Adjust delay as needed
    }
  };

  const updateScrollArrows = () => {
    if (carouselScrollRef.current) {
      const containerWidth = carouselScrollRef.current.clientWidth;
      const contentWidth = carouselScrollRef.current.scrollWidth;
      const leftOffset = carouselScrollRef.current.scrollLeft;
      const paddingOffset = 10;
      setCanScrollLeft(leftOffset > paddingOffset);
      setCanScrollRight(
        contentWidth > containerWidth + leftOffset + paddingOffset,
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      updateScrollArrows();
    }, 150);
    // Also update when items change
  }, [postImages]);

  const removeImageHandler = (index) => {
    let images = postImages;
    images.splice(index, 1);
    updatePostImages([...images]);
  };

  return (
    postImages?.length > 0 && (
      <div className={creationStyles.postPhotosContainer}>
        <h4 style={{marginLeft: 24}}>
          Attached Images {postImages?.length}/{MAX_POST_IMAGES}
        </h4>
        <div style={{height: MAX_IMAGE_HEIGHTS.web, display: 'flex'}}>
          {/* The right chevron has different styles than the back button one (boldness primarily), so we use the right one and flip it */}
          <div className={creationStyles.carouselImgContainer} ref={carouselScrollRef}>
            {canScrollLeft && (
              <div
                className={`${creationStyles.leftCarouselButton} ${creationStyles.carouselButton}`}
                onClick={() => carouselScroll('L')}>
                <ChevronRightIcon
                  width={30}
                  height={30}
                  tintColor={'var(--magenta)'}
                />
              </div>
            )}
            {postImages.map((img, index) => (
              <div
                key={`post-photo-container-${index}`}
                className={`${creationStyles.photoWrapper}`}>
                <div
                  className={creationStyles.modalXContainer}
                  onClick={() => removeImageHandler(index)}>
                  <XIcon tintColor={'var(--white)'} />
                </div>
                <ImageWithAspectRatio src={img?.image || img} />
              </div>
            ))}
            {canScrollRight && (
              <div
                className={`${creationStyles.rightCarouselButton} ${creationStyles.carouselButton}`}
                onClick={() => carouselScroll('R')}>
                <ChevronRightIcon
                  width={30}
                  height={30}
                  tintColor={'var(--magenta)'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

const DEFAULT_MAX_IMAGE_WIDTH = 600;

const CreatedPostImageDisplay = ({post_image, postImages, liked, likeAmount, handleLikePressed, logViewImage, parentType}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusedImgIndex, setFocusedImgIndex] = useState(0);
  const [tallImg, setTallImg] = useState(false);
  const [containerWidth, setContainerWidth] = useState(DEFAULT_MAX_IMAGE_WIDTH);
  const [imageLoadedCount, setImageLoadedCount] = useState(0);
  const containerRef = useRef(null);

  const onImgLoad = ({target: img}) => {
    if (img.offsetWidth / img.offsetHeight < 0.75) {
      setTallImg(true);
    }
    setImageLoadedCount((prev) => prev + 1);
  };

  const multiPhotoClicked = (index) => {
    if (logViewImage) logViewImage();
    setIsModalOpen(true);
    setFocusedImgIndex(index);
  }

  useEffect(() => {
    if (imageLoadedCount >= 2 || (post_image && imageLoadedCount === 1)) {
      setContainerWidth(containerRef.current?.offsetWidth || DEFAULT_MAX_IMAGE_WIDTH);
    }
  }, [imageLoadedCount, postImages?.length, post_image])

  return (
    <div ref={containerRef}>
      {(post_image || postImages?.length === 1) && (
        <div
          className={
            tallImg
              ? `${createdStyles.tallContainer} ${createdStyles.postImageContainer}`
              : createdStyles.postImageContainer
          }>
          <img
            className={[
              tallImg
                ? `${createdStyles.tallImage} ${createdStyles.postImage}`
                : createdStyles.postImage,
            ]}
            src={postImages?.length === 1 ? postImages[0] : post_image}
            alt="User Post Image"
            onClick={() => {
              setIsModalOpen(true);
              logViewImage && logViewImage();
            }}
            onLoad={onImgLoad}
          />
        </div>
      )}
      <Carousel
        onLoadCallback={onImgLoad}
        images={postImages}
        setImageIndex={(i) => setFocusedImgIndex(i)}
        currentImageIndex={focusedImgIndex}
        multiPhotoClicked={multiPhotoClicked}
        feedWidth={containerWidth}
        parentType={parentType}
      />
      {isModalOpen && (
        <PhotoView
          media_url={post_image}
          post_images={postImages}
          index={focusedImgIndex}
          onModalClose={() => setIsModalOpen(false)}
          liked={liked}
          likeAmount={likeAmount}
          onLikeClicked={handleLikePressed}
          setSelectedImage={(i) => setFocusedImgIndex(i)}
        />
      )}
    </div>
  );
};

export {
  CreationPostImageDisplay,
  CreatedPostImageDisplay,
};
